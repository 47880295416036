import React, { useContext, useState } from "react";

import {
  Box,
  Divider,
  Drawer,
  List,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
// import SidebarSearch from './Search';
// import SidebarBottomSection from './SidebarBottomSection';
// import SidebarMenu from './SidebarMenu';
import { SidebarContext } from "../../context/SideBarContext";
import Scrollbar from "../ScrollBar";
import SearchTab from "./SearchBar";
import SidebarBottomSection from "./SideUserOption";
import {
  LockAndKeyValue,
  MainListItems,
  SecondaryListItems,
} from "../listItem/ListItem";

const SidebarWrapper = styled(Box)(
  ({ theme }: { theme: any }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        position: relative;
        z-index: 7;
        height: 100%;
`,
);

const TopSection = styled(Box)(
  ({ theme }) => `
        margin: ${theme.spacing(0.5, 1.5)};
`,
);

function DashboardSideDrawer({
  renderKey,
  setRenderKey,
}: {
  renderKey: string;
  setRenderKey: Function;
}) {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();

  const theme: any = useTheme();

  const [menuSearched, setMenuSearched] = useState("");

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: "none",
            lg: "inline-block",
          },
          position: "fixed",
          left: 0,
          top: 0,
        }}
      >
        <Scrollbar>
          <TopSection>
            <Box
              sx={{
                width: "100%",
                mt: 2,
                mb: 3,
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                flexDirection="row"
                justifyContent="flex-start"
              >
                <Box
                  sx={{
                    width: 10,
                    height: 10,
                    backgroundColor: "black",
                    borderRadius: "50%",
                    marginRight: "8px",
                  }}
                />
                <Typography variant="h4" color="black">
                  Dokandari
                </Typography>
              </Box>
            </Box>
            <SearchTab
              menuSearched={menuSearched}
              setMenuSearched={setMenuSearched}
            />
          </TopSection>

          <List component="nav">
            <MainListItems
              menuSearched={menuSearched}
              renderKey={renderKey}
              setRenderKey={setRenderKey}
            />
            <Divider sx={{ my: 1 }} />
            <SecondaryListItems
              menuSearched={menuSearched}
              renderKey={renderKey}
              setRenderKey={setRenderKey}
            />
            <Divider sx={{ my: 1 }} />
            <LockAndKeyValue
              menuSearched={menuSearched}
              renderKey={renderKey}
              setRenderKey={setRenderKey}
            />
          </List>

          <SidebarBottomSection />
        </Scrollbar>
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`,
        }}
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper>
          <Scrollbar>
            <TopSection>
              <Box
                sx={{
                  width: "100%",
                  mt: 2,
                  mb: 3,
                }}
              >
                <Box
                  display="flex"
                  alignItems={{ xs: "center", md: "center" }}
                  flexDirection={{ xs: "row", md: "row" }}
                  justifyContent="flex-start"
                >
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      backgroundColor: "black",
                      borderRadius: "50%",
                      marginRight: "8px",
                    }}
                  />
                  <Typography variant="h4" color="black">
                    Dokandari
                  </Typography>
                </Box>
              </Box>
            </TopSection>
            <Box
              sx={{
                mx: 1.5,
                my: 2,
              }}
            >
              <SearchTab
                menuSearched={menuSearched}
                setMenuSearched={setMenuSearched}
              />
            </Box>
            <MainListItems
              menuSearched={menuSearched}
              renderKey={renderKey}
              setRenderKey={setRenderKey}
            />
            <Divider />
            <SecondaryListItems
              menuSearched={menuSearched}
              renderKey={renderKey}
              setRenderKey={setRenderKey}
            />
            <Divider />
            <LockAndKeyValue
              menuSearched={menuSearched}
              renderKey={renderKey}
              setRenderKey={setRenderKey}
            />
            <SidebarBottomSection />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default DashboardSideDrawer;
