import { useLocation } from "react-router-dom";
import { useAppSelector } from "./usereduxHooks";
import { useEffect } from "react";

export default function useTextChange() {
  const { pathname } = useLocation();

  const { user } = useAppSelector((store) => store.User);

  useEffect(() => {
    const replaceTextInDOM = (searchText: string, replaceText: string) => {
      const elements = document.querySelectorAll("*");
      elements.forEach((element) => {
        if (
          element.childNodes.length === 1 &&
          element.childNodes[0].nodeType === Node.TEXT_NODE
        ) {
          if (
            element.textContent?.toLowerCase()?.trim() ===
            searchText.toLowerCase()
          ) {
            element.textContent = replaceText;
          }
        }
      });
    };

    setTimeout(() => {
      if (user?.id === 17) {
        replaceTextInDOM("Pcs/bundle", "Bags");

        replaceTextInDOM("Meter/suits", "lbs");
      }
    }, 500);
  }, [pathname, user]);
}
