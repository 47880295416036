import { AnyAction } from "redux";
import { PaginationReceiptSlip } from "../../generated/graphql";
import { LOGOUT_ACTION } from "../action/auth.action";
import { RECEIPT_SUCCESS } from "../action/receipt.action";

export const recieptReducer = (
  state: {
    receipts: PaginationReceiptSlip | null;
  } = {
    receipts: null,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case RECEIPT_SUCCESS:
      return { ...state, receipts: { ...action.payload } };

    case LOGOUT_ACTION:
      return { ...state, receipts: null };

    default:
      return state;
  }
};
