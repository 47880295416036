import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  styled,
} from "@mui/material";
import { useRef, useState } from "react";
import userAvatar from "../../assets/user.png";
import { useAppDispatch, useAppSelector } from "../../hooks/usereduxHooks";
import { logoutAction } from "../../store/actionCreator/auth.creator";
import IconifyIcon from "../icon/Icon";

// Define the type for the UserboxProps
type UserboxProps = {};

const UserBoxButton = styled(Button)(
  ({ theme }: { theme: any }) => `
        padding: ${theme.spacing(0, 1)};
        height: ${theme.spacing(7)};
`,
);

const MenuUserBox = styled(Box)(
  ({ theme }: { theme: any }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`,
);

const UserBoxText = styled(Box)(
  ({ theme }: { theme: any }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`,
);

const UserBoxLabel = styled(Typography)(
  ({ theme }: { theme: any }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`,
);

const UserBoxDescription = styled(Typography)(
  ({ theme }: { theme: any }) => `
        color: ${theme.palette.secondary.light}
`,
);

function HeaderUserbox(props: UserboxProps) {
  const ref = useRef<HTMLButtonElement | null>(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { user } = useAppSelector((store) => store.User);

  const dispatch = useAppDispatch();
  const logout = () => {
    dispatch(logoutAction());
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={user?.name} src={userAvatar} />
        <Box
          component="span"
          sx={{
            display: { xs: "none", md: "inline-block" },
          }}
        >
          <UserBoxText>
            <UserBoxLabel variant="body1">{user?.name}</UserBoxLabel>
          </UserBoxText>
        </Box>
        <Box
          component="span"
          sx={{
            display: { xs: "none", sm: "inline-block" },
          }}
        >
          <ExpandMoreTwoToneIcon
            sx={{
              ml: 1,
            }}
          />
        </Box>
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuUserBox
          sx={{
            minWidth: 210,
          }}
          display="flex"
        >
          <Avatar variant="rounded" alt={user?.name} src={userAvatar} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user?.name}</UserBoxLabel>
          </UserBoxText>
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0,
          }}
        />
        <List
          sx={{
            p: 1,
          }}
          component="nav"
        >
          <ListItem
            onClick={() => {
              logout();
            }}
          >
            <IconifyIcon icon="mdi:logout" />
            <ListItemText
              primary={"Sign out"}
              sx={{
                ml: 1,
                color: "#FF1943",
              }}
            />
          </ListItem>
        </List>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
