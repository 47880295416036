import { AuthType } from "../../generated/graphql";
import { LOGIN_ACTION, LOGOUT_ACTION } from "../action/auth.action";

export const loginAction = (payload: AuthType) => {
  return {
    type: LOGIN_ACTION,
    payload,
  };
};

export const logoutAction = () => {
  return {
    type: LOGOUT_ACTION,
  };
};
