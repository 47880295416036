import { Suspense, lazy } from "react";

import { SaleTypeEnums } from "../generated/graphql";
import SuspenseLoader from "../component/suspenseLoader/SuspenseLoader";
import { OneItemStock } from "../component/itemStock/OneItemStock";

const Loader =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: P) => (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const StaffAccountVoucher = Loader(
  lazy(() => import("../component/staffAccount/StaffAccountVoucher")),
);
const ExpenseAccountVoucher = Loader(
  lazy(() => import("../component/staffAccount/ExpenseVoucher")),
);
const PartyIndex = Loader(lazy(() => import("../component/party/index")));
const AddPaymentSlip = Loader(
  lazy(() => import("../component/paymentSlip/AddPaymentSlip")),
);

const AddReceiptSlip = Loader(
  lazy(() => import("../component/receiptSlip/AddReceiptSlip")),
);
const AddParty = Loader(
  lazy(() => import("../component/addPartyModal/AddPartyModal")),
);
const AccountBalance = Loader(
  lazy(() => import("../component/accountBalance/AccountBalance")),
);

const AddProductModal = Loader(
  lazy(() => import("../component/addProductModal/AddProductModal")),
);

const ItemStockIndex = Loader(lazy(() => import("../component/itemStock")));
const ItemSetupIndex = Loader(lazy(() => import("../component/itemSetup")));

const PurchaseInvoice = Loader(
  lazy(() => import("../component/purchaseInvoice/PurchaseInvoice")),
);

const CreateReceipt = Loader(
  lazy(() => import("../component/receipt/CreateReceipt")),
);

const ListPaymentSlip = Loader(
  lazy(() => import("../component/paymentSlip/ListPaymentItem")),
);

const ListReceiptItem = Loader(
  lazy(() => import("../component/receiptSlip/ListReceiptItem")),
);

const BankVoucherCodeAccount = Loader(
  lazy(() => import("../component/backVoucher/BankVoucherCodeAccount")),
);

const CreateBankVoucher = Loader(
  lazy(() => import("../component/backVoucher/CreateBankVoucher")),
);

const BeforeBalance = Loader(
  lazy(() => import("../component/balance/BeforeBalance")),
);

const ProfileLoss = Loader(
  lazy(() => import("../component/profitLoss/ProfitLoss")),
);

const CaseBookList = Loader(
  lazy(() => import("../component/caseBook/CaseBookList")),
);

const LockRenderComponent = Loader(
  lazy(() => import("../component/dashboard/LockRenderComponent")),
);

const CreateExpView = Loader(
  lazy(() => import("../component/expenditure/CreateExpView")),
);

const ExpenditureVoucher = Loader(
  lazy(() => import("../component/expenditure/ExpenditureVoucher")),
);

const SaveAcccountVoucher = Loader(
  lazy(() => import("../component/saveAcccount/SaveAcccountVoucher")),
);

const MonthlyReportsTable = Loader(
  lazy(() => import("../component/monthlyReports")),
);

const MonthlyExpenseReportsTable = Loader(
  lazy(() => import("../component/monthlyExpenseReports")),
);

const PartnerAccountVoucher = Loader(
  lazy(() => import("../component/partnerVoucher/PartnerAccountVoucher")),
);

const CreatePartnerAccountCode = Loader(
  lazy(() => import("../component/partnerVoucher/PartnerVoucher")),
);

const CreateVoucherType = Loader(
  lazy(() => import("../component/voucherType/CreateVoucherType")),
);

const ExpenditureVoucherType = Loader(
  lazy(() => import("../component/voucherType/ExpenditureVoucherType")),
);

const ParticularPartyDetails = Loader(
  lazy(() => import("../component/accountBalance/ParticularPartyDetails")),
);
const ParticularVoucherDetails = Loader(
  lazy(() => import("../component/VoucherLedger/ParticularVoucherDetails")),
);

const sidebarRoutes = [
  {
    path: "/",
    element: <PartyIndex />,
  },
  {
    path: "/accounts",
    children: [
      {
        path: "list",
        element: <PartyIndex />,
      },
      {
        path: "add-party",
        element: <AddParty />,
      },
      {
        path: "add-party/:partyId",
        element: <AddParty />,
      },
      {
        path: "ledger",

        children: [
          {
            path: ":partyId",
            element: <ParticularPartyDetails />,
          },
          {
            path: "",
            element: <AccountBalance />,
          },
        ],
      },
    ],
  },

  {
    path: "/items",
    children: [
      {
        path: "list",
        element: <ItemSetupIndex />,
      },
      {
        path: "add-item",
        element: <AddProductModal />,
      },
      {
        path: "add-item/:editCode",
        element: <AddProductModal />,
      },
      {
        path: "stock",
        children: [
          {
            path: ":itemCode",
            element: <OneItemStock />,
          },
          {
            path: "",
            element: <ItemStockIndex />,
          },
        ],
      },
    ],
  },

  {
    path: "/sales",
    children: [
      {
        path: "create-new",
        element: <CreateReceipt type={SaleTypeEnums.Sale} />,
      },
      {
        path: "list",
        element: <PurchaseInvoice type={SaleTypeEnums.Sale} />,
      },
    ],
  },

  {
    path: "/purchases",
    children: [
      {
        path: "create-new",
        element: <CreateReceipt type={SaleTypeEnums.Purchase} />,
      },
      {
        path: "list",
        element: <PurchaseInvoice type={SaleTypeEnums.Purchase} />,
      },
    ],
  },

  {
    path: "/payments",

    children: [
      {
        path: "create-new",
        element: <AddPaymentSlip />,
      },

      {
        path: "edit-payment",
        element: <AddPaymentSlip />,
      },
      {
        path: "list",
        element: <ListPaymentSlip />,
      },
    ],
  },

  {
    path: "/receipts",
    children: [
      {
        path: "create-new",
        element: <AddReceiptSlip />,
      },

      {
        path: "edit-receipt",
        element: <AddReceiptSlip />,
      },

      {
        path: "list",
        element: <ListReceiptItem />,
      },
    ],
  },

  {
    path: "/vouchers",
    children: [
      {
        path: "create-voucher-code",
        element: <CreateVoucherType />,
      },
      {
        path: "voucher-code-list",
        element: <ExpenditureVoucherType />,
      },
      {
        path: "create-new",
        element: <CreateExpView />,
      },
      {
        path: "list",
        element: <ExpenditureVoucher />,
      },
    ],
  },
  {
    path: "staff/list",
    element: <StaffAccountVoucher />,
  },
  {
    path: "/cash-book",
    element: <CaseBookList />,
  },
  {
    path: "expense/ledger",
    element: <ExpenseAccountVoucher />,
  },
  {
    path: "/locked",
    children: [
      {
        path: "cash-book-amount",
        element: <BeforeBalance />,
      },
      {
        path: "profit-loss-sheet",
        element: <ProfileLoss />,
      },

      {
        path: "reports/sell-purchase-reports",
        element: <MonthlyReportsTable />,
      },
      {
        path: "reports/expense-reports",
        element: <MonthlyExpenseReportsTable />,
      },
      {
        path: "reports/totals",
        element: (
          <>
            <h1>Totals</h1>
          </>
        ),
      },
      {
        path: "partners/create-new",
        element: <CreatePartnerAccountCode />,
      },
      {
        path: "partners/list",
        element: <PartnerAccountVoucher />,
      },
      {
        path: "voucher/legder/:accountId",
        element: <ParticularVoucherDetails />,
      },

      {
        path: "banks/create-new",
        element: <CreateBankVoucher />,
      },
      {
        path: "safe-accounts/list",
        element: <SaveAcccountVoucher />,
      },

      {
        path: "banks/list",
        element: <BankVoucherCodeAccount />,
      },

      {
        path: "safe-accounts",
        element: <></>,
      },
      {
        path: "trading",
        element: <></>,
      },
      {
        path: "balance",
        element: <></>,
      },
      {
        path: "",
        element: <LockRenderComponent children={<></>} />,
      },
    ],
  },
];

// values: [
//   { title: "Create Voucher Code", route: "create-voucher-code" },
//   { title: "Voucher codes List", route: "voucher-code-list" },
//   { title: "Create new", route: "create-new" },
//   { title: "Vouchers List", route: "list" },
// ],

export default sidebarRoutes;
