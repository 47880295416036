import LoadingButton from "@mui/lab/LoadingButton";
import { Box, TextField } from "@mui/material";

import { FormikProvider, useFormik } from "formik";
import { useRegisterUserMutation } from "../../generated/graphql";
import { useAppDispatch } from "../../hooks/usereduxHooks";
import { loginAction } from "../../store/actionCreator/auth.creator";
import { sweetAlert } from "../../utils/sweetAlert";
import { SignupValidation } from "../../validation/signupFeildValidation";

export default function UserNameInput() {
  const dispatch = useAppDispatch();
  const [signUpRequest] = useRegisterUserMutation({
    onCompleted: (data) => {
      const { email, jwtToken, name, id, timestamps } = data.registerUser;
      dispatch(
        loginAction({ email, jwtToken, name, id, password: "", timestamps }),
      );
    },
    onError: (err) => {
      sweetAlert(err.message, "error");
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await signUpRequest({
          variables: {
            RegisterUserInput: {
              ...values,
            },
          },
        });
      } catch (err) {}
    },
    initialValues: {
      email: "",
      name: "",
      password: "",
    },
    validationSchema: SignupValidation,
  });

  const {
    handleSubmit,
    handleBlur,
    errors,
    handleChange,
    touched,
    values,
    isSubmitting,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Name"
          placeholder="Name"
          id="name"
          value={values.name}
          aria-required
          name="name"
          className="SignInField"
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="name"
          autoFocus
          error={Boolean(touched.name && errors.name)}
          helperText={touched.name ? errors.name : ""}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          label="Email"
          placeholder="Email"
          id="Email"
          value={values.email}
          aria-required
          className="SignInField"
          name="email"
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(touched.email && errors.email)}
          helperText={touched.email ? errors.email : ""}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          placeholder="Password"
          id="password"
          className="SignInField"
          value={values.password}
          aria-required
          name="password"
          label="Password"
          type="password"
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(touched.password && errors.password)}
          helperText={touched.password ? errors.password : ""}
        />
        <LoadingButton
          type="submit"
          loading={isSubmitting}
          loadingIndicator="Loading…"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Register
        </LoadingButton>
      </Box>
    </FormikProvider>
  );
}
