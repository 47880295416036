import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Dayjs from "dayjs";
import { Product, SaleTypeEnums } from "../../generated/graphql";
import { HeadingKeyType } from "../itemSetup/ItemSetup";
import { formattedNum } from "../../utils/constant";

const SaleColumn: HeadingKeyType = {
  Date: { align: "right" },
  Particular: { align: "right" },
  Purchase: { align: "center" },
  Sale: { align: "center" },
  Balance: { align: "center" },
};

export default function StockTable({
  items,
  product,
}: {
  items?: any;
  product?: Product;
}) {
  const [firstItem, ...restItem] = items ?? [];
  return (
    <Grid>
      <Table
        sx={{
          minWidth: 400,
          overflow: "scroll",
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            {Object.keys(SaleColumn).map((column) => {
              return (
                <TableCell
                  key={column}
                  colSpan={
                    column === "Purchase" ||
                    column === "Sale" ||
                    column === "Balance"
                      ? 2
                      : 1
                  }
                  align={SaleColumn[column].align}
                >
                  <Typography
                    fontWeight={"600"}
                    component={"body"}
                    variant={"body1"}
                  >
                    {column}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell align="right">
              <b>PCS/bundle</b>
            </TableCell>
            <TableCell align="right">
              <b>mtrs/suits</b>
            </TableCell>
            <TableCell align="right">
              <b>PCS/bundle</b>
            </TableCell>
            <TableCell align="right">
              <b>mtrs/suits</b>
            </TableCell>
            <TableCell align="right">
              <b>PCS/bundle</b>
            </TableCell>
            <TableCell align="right">
              <b>mtrs/suits</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                gutterBottom
                noWrap
              >
                Initial Stock
              </Typography>
            </TableCell>
            <TableCell></TableCell>

            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell align="center">
              <b>{product?.sumUpPcsOrBundleBalance || "0"}</b>
            </TableCell>
            <TableCell align="center">
              <b>{product?.sumUpMeterOrSuitBalance?.toFixed(2) || "0"}</b>
            </TableCell>
          </TableRow> */}
          {items?.map((item: Product, index: number) => (
            <TableRow key={item.id}>
              <TableCell align="right">
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  {index === 0
                    ? "Initial Stock"
                    : Dayjs(item?.sale?.date).format("DD/MM/YYYY")}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  {item.sale?.invoiceNumber}{" "}
                  {item.sale?.returnSale ? (
                    <span style={{ color: "red" }}>Return</span>
                  ) : (
                    <></>
                  )}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  {(item.sale?.type === SaleTypeEnums.Purchase &&
                    !item.sale?.returnSale) ||
                  (item.sale?.type === SaleTypeEnums.Sale &&
                    item.sale?.returnSale)
                    ? item.pcsOrBundle
                    : "-"}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  {(item.sale?.type === SaleTypeEnums.Purchase &&
                    !item.sale?.returnSale) ||
                  (item.sale?.type === SaleTypeEnums.Sale &&
                    item.sale?.returnSale)
                    ? item.meterOrSuit.toFixed(2)
                    : "-"}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  {(item.sale?.type === SaleTypeEnums.Sale &&
                    !item.sale?.returnSale) ||
                  (item.sale?.type === SaleTypeEnums.Purchase &&
                    item.sale?.returnSale)
                    ? item.pcsOrBundle
                    : "-"}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  {(item.sale?.type === SaleTypeEnums.Sale &&
                    !item.sale?.returnSale) ||
                  (item.sale?.type === SaleTypeEnums.Purchase &&
                    item.sale?.returnSale)
                    ? item.meterOrSuit.toFixed(2)
                    : "-"}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  {item.sumUpPcsOrBundleBalance}
                </Typography>
              </TableCell>

              <TableCell align="right">
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  {item.sumUpMeterOrSuitBalance!.toFixed(2)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}

          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell sx={{ textAlign: "right" }}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="blue"
                gutterBottom
                noWrap
              >
                {(() => {
                  const totaldebit = restItem
                    ?.map((item: Product) => {
                      if (
                        (item.sale?.type === SaleTypeEnums.Purchase &&
                          !item.sale?.returnSale) ||
                        (item.sale?.type === SaleTypeEnums.Sale &&
                          item.sale?.returnSale)
                      ) {
                        return item.pcsOrBundle;
                      } else return 0;
                    })
                    .reduce((total: number, num: number) => {
                      return total + num;
                    }, 0);

                  return formattedNum(
                    totaldebit + firstItem?.sumUpPcsOrBundleBalance,
                  );
                })()}
              </Typography>
            </TableCell>
            <TableCell sx={{ textAlign: "right" }}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="blue"
                gutterBottom
                noWrap
              >
                {(() => {
                  const totaldebit = restItem
                    ?.map((item: Product) => {
                      if (
                        (item.sale?.type === SaleTypeEnums.Purchase &&
                          !item.sale?.returnSale) ||
                        (item.sale?.type === SaleTypeEnums.Sale &&
                          item.sale?.returnSale)
                      ) {
                        return item.meterOrSuit;
                      } else return 0;
                    })
                    .reduce((total: number, num: number) => {
                      return total + num;
                    }, 0);

                  return formattedNum(
                    totaldebit + firstItem?.sumUpMeterOrSuitBalance,
                  );
                })()}
              </Typography>
            </TableCell>
            <TableCell sx={{ textAlign: "right" }}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="blue"
                gutterBottom
                noWrap
              >
                {(() => {
                  const totaldebit = restItem
                    ?.map((item: Product) => {
                      if (
                        (item.sale?.type === SaleTypeEnums.Sale &&
                          !item.sale?.returnSale) ||
                        (item.sale?.type === SaleTypeEnums.Purchase &&
                          item.sale?.returnSale)
                      ) {
                        return item.pcsOrBundle;
                      } else return 0;
                    })
                    .reduce((total: number, num: number) => {
                      return total + num;
                    }, 0);

                  return totaldebit;
                })()}
              </Typography>
            </TableCell>
            <TableCell sx={{ textAlign: "right" }}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="blue"
                gutterBottom
                noWrap
              >
                {(() => {
                  const totaldebit = restItem
                    ?.map((item: Product) => {
                      if (
                        (item.sale?.type === SaleTypeEnums.Sale &&
                          !item.sale?.returnSale) ||
                        (item.sale?.type === SaleTypeEnums.Purchase &&
                          item.sale?.returnSale)
                      ) {
                        return item.meterOrSuit;
                      } else return 0;
                    })
                    .reduce((total: number, num: number) => {
                      return total + num;
                    }, 0);

                  return formattedNum(totaldebit);
                })()}
              </Typography>
            </TableCell>
            <TableCell sx={{ textAlign: "right" }}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="blue"
                gutterBottom
                noWrap
              >
                {(() => {
                  if (!firstItem) {
                    return "";
                  }
                  const totalPurcahse = restItem
                    ?.map((item: Product) => {
                      if (
                        (item.sale?.type === SaleTypeEnums.Purchase &&
                          !item.sale?.returnSale) ||
                        (item.sale?.type === SaleTypeEnums.Sale &&
                          item.sale?.returnSale)
                      ) {
                        return item.pcsOrBundle;
                      } else return 0;
                    })
                    .reduce((total: number, num: number) => {
                      return total + num;
                    }, 0);

                  const totalSale = restItem
                    ?.map((item: Product) => {
                      if (
                        (item.sale?.type === SaleTypeEnums.Sale &&
                          !item.sale?.returnSale) ||
                        (item.sale?.type === SaleTypeEnums.Purchase &&
                          item.sale?.returnSale)
                      ) {
                        return item.pcsOrBundle;
                      } else return 0;
                    })
                    .reduce((total: number, num: number) => {
                      return total + num;
                    }, 0);

                  return (
                    totalPurcahse +
                    firstItem.sumUpPcsOrBundleBalance -
                    totalSale
                  );
                })()}
              </Typography>
            </TableCell>
            <TableCell sx={{ textAlign: "right" }}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="blue"
                gutterBottom
                noWrap
              >
                {(() => {
                  if (!firstItem) {
                    return "";
                  }
                  const totalPurcahse = restItem
                    ?.map((item: Product) => {
                      if (
                        (item.sale?.type === SaleTypeEnums.Purchase &&
                          !item.sale?.returnSale) ||
                        (item.sale?.type === SaleTypeEnums.Sale &&
                          item.sale?.returnSale)
                      ) {
                        return item.meterOrSuit;
                      } else return 0;
                    })
                    .reduce((total: number, num: number) => {
                      return total + num;
                    }, 0);

                  const totalSale = restItem
                    ?.map((item: Product) => {
                      if (
                        (item.sale?.type === SaleTypeEnums.Sale &&
                          !item.sale?.returnSale) ||
                        (item.sale?.type === SaleTypeEnums.Purchase &&
                          item.sale?.returnSale)
                      ) {
                        return item.meterOrSuit;
                      } else return 0;
                    })
                    .reduce((total: number, num: number) => {
                      return total + num;
                    }, 0);

                  return formattedNum(
                    totalPurcahse +
                      firstItem.sumUpMeterOrSuitBalance -
                      totalSale,
                  );
                })()}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
}
