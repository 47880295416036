import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Collapse,
  Grid,
  List,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { NavLink as RouterLink } from "react-router-dom";
import {
  HierarchyItem,
  HierarchyItemSection2,
  UnderLockAndKey,
} from "../../utils/constant";
import IconifyIcon from "../icon/Icon";

import { SidebarContext } from "../../context/SideBarContext";
import { LockContext } from "../../App";

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    margin-bottom: ${theme.spacing(0)};
    padding-left: ${theme.spacing(0.5)};
    padding-right: ${theme.spacing(0.5)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }
`,
);

const SubMenuWrapper = styled(Box)(
  ({ theme }: { theme: any }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(5.5)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: 400;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          background-color: ${theme.sidebar.menuItemBg};
          width: 100%;
          border-radius: 8px;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 1.6)};
          font-weight: 400;
    
          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(["color"])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
            color: ${theme.sidebar.menuItemIconColor};
          }
          
          .MuiButton-endIcon {
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.Mui-active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
          }

          &.Mui-active {
            &:after {
              width: 5px;
              opacity: 1;
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(8)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.7, 1.9, 0.7, 1.9)};

              .MuiBadge-root {
                right: ${theme.spacing(5.5)};
              }

              &:before {
                content: ' ';
                background: ${theme.sidebar.menuItemIconColorActive};
                opacity: 0;
                transition: ${theme.transitions.create([
                  "transform",
                  "opacity",
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.Mui-active,
              &:hover {
                background-color: ${theme.sidebar.menuItemBg};

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }

                &:after {
                  opacity: 0;
                }
              }
            }
          }
        }
      }
    }
`,
);

export const MainListItems = ({
  renderKey,
  setRenderKey,
  menuSearched,
}: {
  renderKey: string;
  setRenderKey: Function;
  menuSearched: string;
}) => {
  const [open, setOpen] = React.useState<string[]>([]);

  const handleClick = (title: string) => {
    const indexItem = open.indexOf(title);
    if (indexItem === -1) {
      open.push(title);
      setOpen([...open]);
    } else {
      open.splice(indexItem, 1);
      setOpen([...open]);
    }
  };

  const { closeSidebar } = React.useContext(SidebarContext);

  const theme: any = useTheme();
  return (
    <React.Fragment>
      <MenuWrapper>
        {Object.values(HierarchyItem)
          .filter(
            ({ title, values }) =>
              title.toLowerCase().startsWith(menuSearched.toLowerCase()) ||
              values.filter(({ title }) =>
                title.toLowerCase().startsWith(menuSearched.toLowerCase()),
              ).length,
          )
          .map(({ type, mainIcon, title, base, values }) => {
            if (type === "single")
              return (
                <ListItemButton
                  component={RouterLink}
                  to={`${base}`}
                  key={title}
                  selected={renderKey === title}
                  onClick={() => setRenderKey(title)}
                >
                  <ListItemIcon>
                    <IconifyIcon icon={mainIcon} />
                  </ListItemIcon>
                  <ListItemText primary={title} />
                </ListItemButton>
              );
            else
              return (
                <>
                  <ListItemButton
                    onClick={() => {
                      handleClick(title);
                    }}
                  >
                    <ListItemIcon>
                      <IconifyIcon icon={mainIcon} />
                    </ListItemIcon>
                    <ListItemText primary={title} />
                    {open.includes(title) ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={Boolean(open.includes(title))} timeout="auto">
                    <SubMenuWrapper>
                      <List style={{ marginLeft: "10px" }} component="div">
                        {values.map((item, index) => {
                          return (
                            <ListItemButton
                              component={RouterLink}
                              to={`${base}${item.route}`}
                              selected={renderKey === item.title}
                              onClick={() => {
                                setRenderKey(item.title);
                                closeSidebar();
                              }}
                            >
                              <ListItemText
                                primary={
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                      }}
                                    >
                                      <div
                                        style={{
                                          background:
                                            renderKey === item.title
                                              ? theme.palette?.primary?.main
                                              : "none",
                                          borderRadius: 50,
                                          width: "10px",
                                          height: "10px",
                                          marginRight: "20px",
                                        }}
                                      />
                                      <Typography
                                        color={
                                          renderKey === item.title
                                            ? "primary"
                                            : ""
                                        }
                                        sx={{
                                          fontWeight: "inherit",
                                          fontSize: "inherit",
                                        }}
                                        fontWeight={500}
                                      >
                                        {item.title}
                                      </Typography>
                                    </div>
                                  </>
                                }
                              />
                            </ListItemButton>
                          );
                        })}
                      </List>
                    </SubMenuWrapper>
                  </Collapse>
                </>
              );
          })}
      </MenuWrapper>
    </React.Fragment>
  );
};

export const SecondaryListItems = ({
  renderKey,
  setRenderKey,
  menuSearched,
}: {
  renderKey: string;
  menuSearched: string;

  setRenderKey: Function;
}) => {
  const [open, setOpen] = React.useState<string[]>([]);

  const handleClick = (title: string) => {
    const indexItem = open.indexOf(title);
    if (indexItem === -1) {
      open.push(title);
      setOpen([...open]);
    } else {
      open.splice(indexItem, 1);
      setOpen([...open]);
    }
  };

  const theme: any = useTheme();

  return (
    <React.Fragment>
      <MenuWrapper>
        {Object.values(HierarchyItemSection2)
          .filter(
            ({ title, values }) =>
              title.toLowerCase().startsWith(menuSearched.toLowerCase()) ||
              values.filter((title) =>
                title.title
                  .toLowerCase()
                  .startsWith(menuSearched.toLowerCase()),
              ).length,
          )
          .map(({ type, mainIcon, title, base, values, icons }) => {
            if (type === "single")
              return (
                <ListItemButton
                  component={RouterLink}
                  to={`${base}`}
                  key={title}
                  selected={renderKey === title}
                  onClick={() => setRenderKey(title)}
                >
                  <ListItemIcon>
                    <IconifyIcon icon={mainIcon} />
                  </ListItemIcon>
                  <ListItemText sx={{ m: 0, p: 0 }} primary={title} />
                </ListItemButton>
              );
            else
              return (
                <>
                  <ListItemButton
                    onClick={() => {
                      handleClick(title);
                    }}
                  >
                    <ListItemIcon>
                      <IconifyIcon icon={mainIcon} />
                    </ListItemIcon>
                    <ListItemText sx={{ m: 0, p: 0 }} primary={title} />
                    {open.includes(title) ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={Boolean(open.includes(title))} timeout="auto">
                    <SubMenuWrapper>
                      <List style={{ marginLeft: "10px" }} component="div">
                        {values.map((item, index) => {
                          return (
                            <ListItemButton
                              component={RouterLink}
                              to={`${base}${item.route}`}
                              selected={renderKey === item.title}
                              onClick={() => setRenderKey(item.title)}
                            >
                              {/* <ListItemIcon>
                              <IconifyIcon
                                color={
                                  renderKey === item
                                    ? theme.palette?.primary?.main
                                    : theme.platte?.secondary?.main
                                }
                                icon={icons[index]}
                              />
                            </ListItemIcon> */}
                              <ListItemText
                                primary={
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                      }}
                                    >
                                      <div
                                        style={{
                                          background:
                                            renderKey === item.title
                                              ? theme.palette?.primary?.main
                                              : "none",
                                          borderRadius: 50,
                                          width: "10px",
                                          height: "10px",
                                          marginRight: "20px",
                                        }}
                                      />
                                      <Typography
                                        color={
                                          renderKey === item.title
                                            ? "primary"
                                            : ""
                                        }
                                        sx={{
                                          fontWeight: "inherit",
                                          fontSize: "inherit",
                                        }}
                                        fontWeight={500}
                                      >
                                        {item.title}
                                      </Typography>
                                    </div>
                                  </>
                                }
                              />
                            </ListItemButton>
                          );
                        })}
                      </List>
                    </SubMenuWrapper>
                  </Collapse>
                </>
              );
          })}
      </MenuWrapper>
    </React.Fragment>
  );
};

export const LockAndKeyValue = ({
  renderKey,
  setRenderKey,
  menuSearched,
}: {
  renderKey: string;
  menuSearched: string;

  setRenderKey: Function;
}) => {
  const [open, setOpen] = React.useState<string[]>([]);

  const [lockOpenOption, setLockClose] = React.useState(false);

  const handleClick = (title: string) => {
    const indexItem = open.indexOf(title);
    if (indexItem === -1) {
      open.push(title);
      setOpen([...open]);
    } else {
      open.splice(indexItem, 1);
      setOpen([...open]);
    }
  };

  const theme: any = useTheme();

  const { lockOpen } = React.useContext(LockContext);

  return (
    <React.Fragment>
      <ListItemButton
        component={RouterLink}
        to={`/locked`}
        onClick={() => {
          //  LockRenderComponent

          setLockClose(!lockOpenOption);
        }}
      >
        <ListItemText sx={{ m: 0, p: 0, height: 50 }} primary={""} />
      </ListItemButton>
      <Collapse in={lockOpen && lockOpenOption} timeout="auto">
        <Grid sx={{ marginLeft: "10px" }}>
          <MenuWrapper>
            {Object.values(UnderLockAndKey)
              .filter(
                ({ title, values }) =>
                  title.toLowerCase().startsWith(menuSearched.toLowerCase()) ||
                  values.filter((title) =>
                    title.title
                      .toLowerCase()
                      .startsWith(menuSearched.toLowerCase()),
                  ).length,
              )
              .map(({ type, mainIcon, title, base, values, icons }) => {
                if (type === "single")
                  return (
                    <ListItemButton
                      component={RouterLink}
                      to={`${base}`}
                      key={title}
                      selected={renderKey === title}
                      onClick={() => setRenderKey(title)}
                    >
                      <ListItemIcon>
                        <IconifyIcon icon={mainIcon} />
                      </ListItemIcon>
                      <ListItemText sx={{ m: 0, p: 0 }} primary={title} />
                    </ListItemButton>
                  );
                else
                  return (
                    <>
                      <ListItemButton
                        onClick={() => {
                          handleClick(title);
                        }}
                      >
                        <ListItemIcon>
                          <IconifyIcon icon={mainIcon} />
                        </ListItemIcon>
                        <ListItemText sx={{ m: 0, p: 0 }} primary={title} />
                        {open.includes(title) ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse
                        in={Boolean(open.includes(title))}
                        timeout="auto"
                      >
                        <SubMenuWrapper>
                          <List style={{ marginLeft: "10px" }} component="div">
                            {values.map((item, index) => {
                              return (
                                <ListItemButton
                                  component={RouterLink}
                                  to={`${base}${item.route}`}
                                  selected={renderKey === item.title}
                                  onClick={() => setRenderKey(item.title)}
                                >
                                  <ListItemText
                                    primary={
                                      <>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                          }}
                                        >
                                          <div
                                            style={{
                                              background:
                                                renderKey === item.title
                                                  ? theme.palette?.primary?.main
                                                  : "none",
                                              borderRadius: 50,
                                              width: "10px",
                                              height: "10px",
                                              marginRight: "20px",
                                            }}
                                          />
                                          <Typography
                                            color={
                                              renderKey === item.title
                                                ? "primary"
                                                : ""
                                            }
                                            sx={{
                                              fontWeight: "inherit",
                                              fontSize: "inherit",
                                            }}
                                            fontWeight={500}
                                          >
                                            {item.title}
                                          </Typography>
                                        </div>
                                      </>
                                    }
                                  />
                                </ListItemButton>
                              );
                            })}
                          </List>
                        </SubMenuWrapper>
                      </Collapse>
                    </>
                  );
              })}
          </MenuWrapper>
        </Grid>
      </Collapse>
    </React.Fragment>
  );
};
