import MainLayout from "../Layout/MainLayout";
import SignIn from "../component/signIn/SignIn";
import SignUp from "../component/signUp/Signup";
import PrivateRoute from "../routes/PrivateRoute";
import PublicRoute from "../routes/PublicRoute";
import sidebarRoutes from "./SidebarRoutes";

const router = [
  {
    path: "/",
    element: (
      <PrivateRoute>
        <MainLayout />
      </PrivateRoute>
    ),
    children: sidebarRoutes,
  },
  {
    path: "/signin",
    element: (
      <PublicRoute>
        <SignIn />
      </PublicRoute>
    ),
  },
  {
    path: "/signup",
    element: (
      <PrivateRoute>
        <SignUp />
      </PrivateRoute>
    ),
  },
];

export default router;
