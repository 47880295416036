import { AnyAction } from "redux";
import { PaginationProduct } from "../../generated/graphql";
import { LOGOUT_ACTION } from "../action/auth.action";
import { PRODUCT_SUCCESS } from "../action/product.action";

export const itemsReducer = (
  state: {
    products: PaginationProduct | null;
  } = {
    products: null,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case PRODUCT_SUCCESS:
      return { ...state, products: { ...action.payload } };

    case LOGOUT_ACTION:
      return { ...state, products: null };

    default:
      return state;
  }
};
