import { Box, useTheme } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import DashboardSideDrawer from "../component/dashboard/Dashboard";
import Header from "../component/dashboard/Header";

const MainLayout = () => {
  const theme: any = useTheme();

  const [renderKey, setRenderKey] = React.useState("");

  return (
    <>
      <Header />
      <DashboardSideDrawer renderKey={renderKey} setRenderKey={setRenderKey} />
      <Box
        sx={{
          position: "relative",
          zIndex: 5,
          flex: 1,
          display: "flex",
          pt: `${theme.header.height}`,
          [theme.breakpoints.up("lg")]: {
            pl: `${theme.sidebar.width}`,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            padding: 3,
            maxWidth: "98vw",
            overflowX: "auto",
            minHeight: "95vh",
            background: theme.palette.background.default,
          }}
        >
          <Box sx={{ overflowX: "auto" }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MainLayout;
