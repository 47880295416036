/* eslint-disable react-hooks/exhaustive-deps */
import { ThemeProvider } from "@emotion/react";
import { Toaster } from "react-hot-toast";
import { responsiveFontSizes } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { createContext, useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import "./App.css";
import { SidebarProvider } from "./context/SideBarContext";
import { useWhoAmILazyQuery } from "./generated/graphql";
import { useAppDispatch } from "./hooks/usereduxHooks";
import router from "./router";
import { loginAction } from "./store/actionCreator/auth.creator";
import { AppTheme } from "./theme/Theme";
import useTextChange from "./hooks/useTextChange";

type LockContextType = {
  lockOpen: boolean;
  setLockState: Function;
};
export const LockContext = createContext<LockContextType>({
  lockOpen: false,
  setLockState: console.log,
});

function App() {
  const [, { refetch }] = useWhoAmILazyQuery();

  useTextChange();
  const dispatch = useAppDispatch();
  useEffect(() => {
    (async () => {
      const { data } = await refetch();
      if (data) {
        const { email, jwtToken, name, id, timestamps } = data.whoAmI;
        dispatch(
          loginAction({ email, jwtToken, name, id, password: "", timestamps }),
        );
      }
    })();
  }, []);

  const [lockOpen, setLockState] = useState<boolean>(false);

  const content = useRoutes(router);

  return (
    <div className="App">
      <Toaster />
      <LockContext.Provider value={{ lockOpen, setLockState }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={responsiveFontSizes(AppTheme)}>
            <SidebarProvider>{content}</SidebarProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </LockContext.Provider>
    </div>
  );
}

export default App;
