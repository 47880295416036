import LoadingButton from "@mui/lab/LoadingButton";
import { FormHelperText, Link, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FormikProvider, useFormik } from "formik";
import { useSignInUserMutation } from "../../generated/graphql";
import { useAppDispatch } from "../../hooks/usereduxHooks";
import { loginAction } from "../../store/actionCreator/auth.creator";
import { sweetAlert } from "../../utils/sweetAlert";

import { signInValidation } from "../../validation/signinValidation";

export default function SignIn() {
  const dispatch = useAppDispatch();
  const [signInRequest] = useSignInUserMutation({
    onCompleted: (data) => {
      const { email, jwtToken, name, id, timestamps } = data.signInUser;
      dispatch(
        loginAction({ email, jwtToken, name, id, password: "", timestamps }),
      );
    },
    onError: (err) => {
      sweetAlert(err.message, "error");
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await signInRequest({
          variables: {
            SignInInput: {
              ...values,
            },
          },
        });
      } catch (err) {
        // AlertError(err.response.data.message);
      }
    },
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: signInValidation,
  });

  const {
    handleSubmit,
    handleBlur,
    errors,
    handleChange,
    touched,
    values,
    isSubmitting,
  } = formik;

  return (
    <div className="App-header">
      <img
        src="/logo.svg"
        alt="technove icon logo"
        style={{
          width: "250px",
          position: "relative",
        }}
      />
      <Paper
        sx={{ margin: 10, position: "relative", top: "-100px" }}
        elevation={0}
      >
        <FormikProvider value={formik}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                textAlign={"center"}
                fontWeight={700}
                component="h1"
                variant="h5"
              >
                Sign in Panel
              </Typography>
              <Box component="form" onSubmit={handleSubmit} noValidate>
                <TextField
                  margin="normal"
                  required
                  className="SignInField sign_in_text_field"
                  fullWidth
                  size="small"
                  placeholder="Email"
                  id="email"
                  name="email"
                  autoComplete="email"
                  value={values.email}
                  aria-required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.email && errors.email)}
                />
                <FormHelperText
                  sx={{
                    color: "error.main",
                    height: "20px",
                    position: "relative",
                    top: "5px",
                    left: "-8px",
                  }}
                >
                  {errors["email"] && (touched.email ? errors.email : "")}
                </FormHelperText>
                <TextField
                  margin="normal"
                  required
                  className="SignInField sign_in_text_field"
                  fullWidth
                  size="small"
                  name="password"
                  placeholder="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={values.password}
                  aria-required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.password && errors.password)}
                />
                <FormHelperText
                  sx={{
                    color: "error.main",
                    height: "20px",
                    left: "-8px",
                    position: "relative",
                    top: "5px",
                  }}
                >
                  {errors["password"] &&
                    (touched.password ? errors.password : "")}
                </FormHelperText>

                <LoadingButton
                  type="submit"
                  loading={isSubmitting}
                  loadingIndicator="Loading…"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </LoadingButton>

                <Box>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    mt={5}
                  >
                    <Typography color={"grey"}>
                      For access, please contact the administrator.
                    </Typography>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </FormikProvider>
      </Paper>
      <Typography sx={{ position: "absolute", bottom: 10 }} color="black">
        A solution by{" "}
        <Link
          color="inherit"
          target="_blank"
          href="https://www.technove.com.au/"
        >
          Technove
        </Link>
      </Typography>
    </div>
  );
}
