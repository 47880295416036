import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getItem } from "../utils/localStorage";
function createApolloClient() {
  const hostname = window.location.href.includes("localhost")
    ? "http://localhost:3001"
    : "https://dukandari-api.technove.com.au";

  // const hostname = "https://dukandari-api.technove.com.au;

  const httpLink = createHttpLink({
    uri: `${hostname}/graphql`,
  });

  const authLink = setContext(async (_, { headers }) => {
    const tokenData = await getItem("token");
    return {
      headers: {
        ...headers,
        authorization: tokenData ? `Bearer ${tokenData?.token}` : "",
      },
    };
  });

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
  });
}

export const client = createApolloClient();
