import { AnyAction } from "redux";
import { PaginationParty } from "../../generated/graphql";
import { LOGOUT_ACTION } from "../action/auth.action";
import { PARTY_SUCCESS } from "../action/party.action";

export const partyReducers = (
  state: {
    parties: PaginationParty | null;
  } = {
    parties: null,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case PARTY_SUCCESS:
      return { ...state, parties: { ...action.payload } };

    case LOGOUT_ACTION:
      return { ...state, parties: null };

    default:
      return state;
  }
};
