import { Box, InputBase, styled } from "@mui/material";
import { useState } from "react";

import IconifyIcon from "../icon/Icon";
import useDebounce from "../../hooks/useDebounce";

const wait = (time: number) => new Promise((res) => setTimeout(res, time));

const SearchInputWrapper = styled(InputBase)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(15)};
    padding: ${theme.spacing(0.5)};
    width: 100%;
    margin-left: ${theme.spacing(0.5)};
    color: ${theme.palette.primary};
`,
);

function SearchTab({
  menuSearched,
  setMenuSearched,
}: {
  menuSearched: string;
  setMenuSearched: Function;
}) {
  const [searchValue, setSearchValue] = useState(menuSearched);

  const submitSearch = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await wait(1500);
  };

  const handleSearchChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.preventDefault();

    if (event.target.value) {
      setSearchValue(event.target.value);
    } else {
      setSearchValue("");
    }
  };

  useDebounce(
    () => {
      setMenuSearched(searchValue);
    },
    1000,
    [searchValue],
  );

  return (
    <>
      <Box>
        <form onSubmit={submitSearch}>
          <Box display="flex" alignItems="center">
            <Box
              flexGrow={1}
              display="flex"
              alignItems="center"
              borderRadius="8px"
              border="1px solid #D4D4D4"
              paddingX="10px"
            >
              <IconifyIcon icon={"mdi:search"} color="#223354" />
              <SearchInputWrapper
                value={searchValue}
                onChange={handleSearchChange}
                placeholder={"Search for menu"}
                fullWidth
              />
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
}

export default SearchTab;
