import Dayjs from "dayjs";
export const limitAllItem = 30;

export const signedUerArray = [
  "ashraf",
  "irfan",
  "mohammed",
  "salim",
  "jawed",
  "Afaq",
  "sarfraz",
];

export const cashAndCheckArray = [
  "Habib bank",
  "Meezan bank",
  "Cash",
  "Easy paisa",
  "Jazz cash",
];

export const getTotalFunc =
  (paramsNeedToSum: string) => (accumulator: number, a: any) => {
    return accumulator + a[paramsNeedToSum];
  };

export const formattedDate = (date: any) => {
  return Dayjs(new Date(Number(date))).format("DD/MM/YYYY");
};
export const formattedDay = (date: any) =>
  Dayjs(new Date(Number(date))).format("dddd");

export const returnCheckNumber = (value: number): number => {
  if (isNaN(value)) {
    return 0;
  }
  return value;
};

export const ListItems = [
  "Party Setup",
  "Item Setup",
  "Sale Invoice",
  "Purchase Invoice",
];

export const HierarchyItem = {
  "Party Setup": {
    type: "nested",
    mainIcon: "mdi:account-outline",
    title: "Account Setup",
    base: "/accounts/",
    values: [
      {
        title: "Add Party",
        route: "add-party",
      },
      {
        title: "Account List",
        route: "list",
      },
      {
        title: "Party ledger",
        route: "ledger",
      },
    ],
    icons: ["bi:file-earmark-bar-graph", "bx:bxs-book-content"],
  },
  "Item Setup": {
    type: "nested",
    mainIcon: "mdi:cog-sync-outline",
    title: "Item Setup",
    base: "/items/",
    values: [
      {
        title: "Add Item",
        route: "add-item",
      },
      {
        title: "Item List",
        route: "list",
      },
      {
        title: "Stock List",
        route: "stock",
      },
    ],
    icons: ["mdi:cog-sync-outline", "bi:clipboard-data", "bi:archive"],
  },
  "Sale Invoice": {
    type: "nested",
    mainIcon: "mdi:receipt-text-check-outline",
    title: "Sale Invoice",
    base: "/sales/",
    values: [
      {
        title: "create new sale",
        route: "create-new",
      },
      {
        title: "Sales List",
        route: "list",
      },
    ],
    icons: ["mdi:receipt-text-check-outline", "pixelarticons:reciept-alt"],
  },
  "Purchase Invoice": {
    type: "nested",
    mainIcon: "mdi:purse-outline",
    title: "Purchase Invoice",
    base: "/purchases/",
    values: [
      {
        title: "create new purchase",
        route: "create-new",
      },
      {
        title: "Purchase List",
        route: "list",
      },
    ],
    icons: ["mdi:purse-outline", "pixelarticons:reciept"],
  },
  "Receipt slip": {
    type: "nested",
    mainIcon: "mdi:printer-pos-check-outline",
    title: "Receipts",
    base: "/receipts/",
    values: [
      {
        title: "create new Receipts",
        route: "create-new",
      },
      {
        title: "Receipts List",
        route: "list",
      },
    ],
    icons: ["mdi:printer-pos-check-outline", "pixelarticons:reciept"],
  },
  "Payment slip": {
    type: "nested",
    mainIcon: "mdi:credit-card-outline",
    title: "Payments",
    base: "/payments/",
    values: [
      {
        title: "create new payment",
        route: "create-new",
      },
      {
        title: "Payment List",
        route: "list",
      },
    ],
    icons: ["mdi:credit-card-outline", "arcticons:expense-register"],
  },
};

export const HierarchyItemSection2 = {
  "Expenditure Voucher": {
    type: "nested",
    mainIcon: "mdi:file-export-outline",
    title: "Voucher",
    base: "/vouchers/",
    values: [
      { title: "Create Voucher Code", route: "create-voucher-code" },
      { title: "Voucher codes List", route: "voucher-code-list" },
      { title: "Create new", route: "create-new" },
      { title: "Vouchers List", route: "list" },
    ],
    icons: [
      "bx:bxs-tag",
      "bi:list-ul",
      "bx:bxs-cog",
      "mdi:file-export-outline",
    ],
  },
  "Staff Account": {
    type: "nested",
    mainIcon: "mdi:handshake-outline",
    title: "Staff Account",
    base: "/staff/",
    values: [{ title: "Staff List", route: "list" }],
    icons: ["mdi:handshake-outline", "bi:person-check"],
  },

  Expense: {
    type: "nested",
    mainIcon: "mdi:calendar-month-outline",
    title: "Expense",
    base: "/expense/",

    values: [{ title: "Expense Ledger", route: "ledger" }],
    icons: ["mdi:calendar-month-outline", "arcticons:expense-register"],
  },
  "Cash Book": {
    type: "single",
    mainIcon: "mdi:cash-sync",
    title: "Cash Book",
    base: "/cash-book/",
    values: [],
    icons: ["mdi:cash-sync"],
  },
};

const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };

// export const LockAndKeySection = {
//   "Under Lock & key": {
//     type: "nested",
//     mainIcon: "mdi:axis-x-y-arrow-lock",
//     title: "Under Lock & key",
//     base: "/locked/",
//     values: [{ title: "Cash book amount", route: "cash-book-amount" }],
//     icons: ["mdi:axis-x-y-arrow-lock"],
//   },
// };

export const UnderLockAndKey = {
  "Monthly Reports": {
    type: "nested",
    mainIcon: "mdi:calendar-month-outline",
    title: "Monthly Reports",
    base: "/locked/reports/",

    values: [
      { title: "Sell P. Reports", route: "sell-purchase-reports" },
      { title: "Expense Reports", route: "expense-reports" },
    ],
    icons: ["mdi:calendar-month-outline", "arcticons:expense-register"],
  },
  // Reports: {
  //   type: "nested",
  //   mainIcon: "mdi:calendar-month-outline",
  //   title: "Totaling Reports",
  //   base: "/locked/reports/",
  //   values: [{ title: "Totals", route: "totals" }],

  //   icons: ["mdi:calendar-month-outline"],
  // },

  "Partner Account": {
    type: "nested",
    mainIcon: "mdi:handshake-outline",
    title: "Partner Account",
    base: "/locked/partners/",
    values: [{ title: "Partner List", route: "list" }],
    icons: ["mdi:handshake-outline", "bi:person-check"],
  },
  "Bank Account": {
    type: "nested",
    mainIcon: "mdi:bank-circle-outline",
    title: "Bank Account",
    base: "/locked/banks/",
    values: [{ title: "Bank List", route: "list" }],
    icons: ["mdi:bank-circle-outline", "bi:bank2"],
  },
  // "Safe Account": {
  //   type: "nested",
  //   mainIcon: "mdi:safety-goggles",
  //   title: "Safe Account",
  //   base: "/locked/safe-accounts/",
  //   values: [{ title: "Safe Account List", route: "list" }],
  //   icons: ["mdi:safety-goggles"],
  // },
  "Trading Account": {
    type: "nested",
    mainIcon: "mdi:bitcoin",
    title: "Trading Account",
    base: "/locked/trade-account/",
    values: [
      { title: "Trading Account Setup", route: "create-new" },
      { title: "Trading Account List", route: "list" },
    ],
    icons: ["mdi:bitcoin"],
  },
  "Balance Account": {
    type: "single",
    mainIcon: "mdi:equal",
    title: "Balance Account",
    base: "/locked/balance/",
    values: [{ title: "Balance Account", route: "balance-account" }],
    icons: ["mdi:equal"],
  },
  "Profit Loss Sheet": {
    type: "single",
    mainIcon: "mdi:finance",
    title: "Profit Loss",
    base: "/locked/profit-loss-sheet/",
    values: [{ title: "Profit Loss", route: "profit-loss-sheet" }],
    icons: ["mdi:finance"],
  },
  "Cash Book Amount": {
    type: "single",
    mainIcon: "mdi:axis-x-y-arrow-lock",
    title: "Cash Book Amount",
    base: "/locked/cash-book-amount",
    values: [{ title: "Cash book amount", route: "cash-book-amount" }],
    icons: ["mdi:axis-x-y-arrow-lock"],
  },
};

export const formattedNum = (digits?: number) =>
  digits ? digits.toLocaleString("en-IN", options) : "0";
