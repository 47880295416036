export const setItem = (key: string, payload: any) => {
  return localStorage.setItem(key, JSON.stringify(payload));
};

export const getItem = (key: string) => {
  return JSON.parse(localStorage.getItem(key)!);
};

export const removeItem = (key: string) => {
  localStorage.removeItem(key);
};
