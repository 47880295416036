import { AnyAction } from "redux";
import { AuthType } from "../../generated/graphql";
import { removeItem, setItem } from "../../utils/localStorage";
import { LOGIN_ACTION, LOGOUT_ACTION } from "../action/auth.action";

export const authReducers = (
  state: {
    user: AuthType | null;
  } = {
    user: null,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case LOGIN_ACTION:
      setItem("token", { token: action.payload.jwtToken });
      return { ...state, user: { ...action.payload } };

    case LOGOUT_ACTION:
      removeItem("token");
      return { ...state, user: null };

    default:
      return state;
  }
};
