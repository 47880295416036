import {
  Avatar,
  Box,
  Button,
  Divider,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import React, { useRef } from "react";

import userAvatar from "../../assets/user.png";
import { useAppDispatch, useAppSelector } from "../../hooks/usereduxHooks";
import { logoutAction } from "../../store/actionCreator/auth.creator";
import IconifyIcon from "../icon/Icon";

// Define the type for the ref
type RefType = React.RefObject<HTMLButtonElement>;

const UserBoxButton = styled(Button)(
  ({ theme }: { theme: any }) => `
    padding: ${theme.spacing(1)};
    background-color: ${alpha(theme.colors.alpha.black[100], 0)};
    margin-top: ${theme.spacing(1)};
    .MuiButton-label {
      justify-content: flex-start;
    }
    &:hover {
      background-color: ${alpha(theme.colors.alpha.black[100], 0.12)};
    }
`,
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
    text-align: left;
    padding-left: ${theme.spacing(1)};
`,
);

const UserBoxLabel = styled(Typography)(
  ({ theme }: { theme: any }) => `
    font-weight: ${theme.typography.fontWeightLight};
    color: ${theme.sidebar.menuItemColor};
    display: block;
    &.popoverTypo {
      color: ${theme.palette.secondary.main};
    }
`,
);

const UserBoxDescription = styled(Typography)(
  ({ theme }: { theme: any }) => `
    color: ${alpha(theme.sidebar.menuItemColor, 0.6)};
    &.popoverTypo {
      color: ${theme.palette.secondary.light};
    }
`,
);

function SidebarBottomSection() {
  const ref: RefType = useRef(null);

  const { user } = useAppSelector((store) => store.User);

  const dispatch = useAppDispatch();
  const logout = () => {
    dispatch(logoutAction());
  };

  return (
    <>
      <Box sx={{ m: 1.5, height: "100px" }} />

      <Box
        sx={{
          p: 1.5,
          position: "fixed",
          bottom: 0,
          background: "white",
        }}
      >
        <Divider />
        <UserBoxButton
          fullWidth
          sx={{ width: "260px" }}
          color="secondary"
          ref={ref}
        >
          <Avatar variant="rounded" alt={user?.name} src={userAvatar} />
          <Box
            display="flex"
            flex={1}
            alignItems="center"
            width={"100%"}
            justifyContent="space-between"
            onClick={logout}
          >
            <UserBoxText sx={{ width: 100 }}>
              <UserBoxLabel variant="body1">{user?.name}</UserBoxLabel>
              <UserBoxDescription variant="body2"></UserBoxDescription>
            </UserBoxText>
            <IconifyIcon icon={"mdi:logout"} />
          </Box>
        </UserBoxButton>
      </Box>
    </>
  );
}

export default SidebarBottomSection;
