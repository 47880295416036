import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import React, { useContext } from "react";

import IconifyIcon from "../icon/Icon";
// import HeaderUserbox from "./UserBox";
import {
  SidebarContext,
  SidebarContextType,
} from "../../context/SideBarContext";
import HeaderUserbox from "./HeaderUserbox";

// Define the type for the HeaderWrapper component
type HeaderWrapperProps = {
  children: React.ReactNode;
};

const HeaderWrapper = styled(Box)<HeaderWrapperProps>(
  ({ theme }: { theme: any }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 10;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(8px);
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`,
);

function Header() {
  const { sidebarToggle, toggleSidebar } =
    useContext<SidebarContextType>(SidebarContext);

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Box display="flex" alignItems="center">
        <Box
          sx={{
            marginLeft: "30px",
            display: { lg: "none", xs: "inline-block" },
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            flexDirection="row"
            justifyContent="flex-start"
          >
            <Box
              sx={{
                width: 10,
                height: 10,
                backgroundColor: "black",
                borderRadius: "50%",
                marginRight: "8px",
              }}
            />
            <Typography variant="h4" color="black">
              Dokandari
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box display="flex" alignItems="center">
        <HeaderUserbox />
        <Box
          component="span"
          sx={{
            display: { lg: "none", xs: "inline-block" },
          }}
        >
          <Tooltip arrow title="Toggle Menu" onClick={toggleSidebar}>
            <IconButton color="secondary">
              {!sidebarToggle ? (
                <IconifyIcon icon={"mdi:menu"} />
              ) : (
                <IconifyIcon icon="mdi:close" />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
