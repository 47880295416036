import { Divider, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import UserNameInput from "./UserNameInput";

export default function SignUp() {
  return (
    <div className="App-header">
      <Paper style={{ margin: 10 }} elevation={3}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            style={{
              margin: "20px",
            }}
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={700} component="h1" variant="h5">
              Register
            </Typography>
            <UserNameInput />

            <Divider />
            <Box>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <RouterLink to={"/signin"}>
                  <Link variant="body2">Go to Sign In</Link>
                </RouterLink>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Paper>
    </div>
  );
}
