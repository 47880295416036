import { configureStore } from "@reduxjs/toolkit";
import { authReducers } from "./reducer/auth.reducer";
import { ledgerReducers } from "./reducer/ledger.reducer";
import { partyReducers } from "./reducer/party.reducer";
import { itemsReducer } from "./reducer/item.reducer";
import { recieptReducer } from "./reducer/receipt.reducer";
// ...

export const store = configureStore({
  reducer: {
    User: authReducers,
    Ledger: ledgerReducers,
    Party: partyReducers,
    items: itemsReducer,
    receipts: recieptReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
